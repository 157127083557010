import { createGlobalStyle } from 'styled-components';

export const ColorPalette = {
	headline: '#094067',
	background: '#fffffe',
	paragraph: '#5f6c7b',
	button: '#3da9fc',
	buttonText: '#fffffe',
	stroke: '#094067',
	main: '#fffffe',
	highlight: '#3da9fc',
	secondary: '#90b4ce',
	tertiary: '#ef4565'
};

export const GlobalStyles = createGlobalStyle`
	body {
		font-family: 'Montserrat', sans-serif;
		color: ${ColorPalette.headline};
		background-color: ${ColorPalette.background};
		padding: 0 5px;

		@media screen and (min-width: 720px) {
			padding: 0 50px
		}

		@media screen and (min-width: 1024px) {
			padding: 0 100px
		}

		@media screen and (min-width: 1440px) {
			padding: 0 200px
		}

		@media screen and (min-width: 1920px) {
			padding: 0 400px
		}

		@media screen and (min-width: 2560px) {
			padding: 0 600px
		}
	}

	a {
		text-decoration: none;
		color: ${ColorPalette.headline};
	}

	* {
		box-sizing: border-box;
	}
`;
