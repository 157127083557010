import React, { lazy, Suspense } from 'react';
// import { Switch, Route } from 'react-router-dom';

// import Header from '../components/header/header.component';
import Spinner from '../components/spinner/spinner.component';
import ErrorBoundary from '../components/error-boundary/error-boundary.component';

import { GlobalStyles } from './global.styles';

const Hero = lazy(() => import('../components/hero/hero.component'));
const AboutPage = lazy(() =>
	import('../pages/about/about.component')
);
const ProjectsPage = lazy(() =>
	import('../pages/projects/projects.component')
);
const ContactPage = lazy(() =>
	import('../pages/contact/contact.component')
);

const App = () => {
	return (
		<div>
			<GlobalStyles />
			{/* <Header /> */}
			<ErrorBoundary>
				<Suspense fallback={<Spinner />}>
					<Hero />
					<AboutPage />
					<ProjectsPage />
					<ContactPage />
				</Suspense>
			</ErrorBoundary>
		</div>
	);
};

export default App;
